

:root {
  --black--: #000;
  --white--: #fff;
  --prime--: #b93160;
  --blackPrime--: #b93160;
  --grayPrime--: #cfcfcf;
  --inputBorder--: #cbd5e1;
  --btnPrime--: #b93160;
  --tablePrime--: #e2e8f0;
  --scrollColor--: #b6b6b6;
  --headerPrime--: #f0f4f8;
  --fontJakarta--: "Plus Jakarta Sans", sans-serif;

  --TableColumnBold--: #334155;
  --TableFont--: #555555;
  /* New Color */
  --themePrime--: #b93160;
}

/* ======================== Start Input CSS ======================== */

/* all Fields css */



.prime-input {
  margin-bottom: 15px;
  position: relative;
}

.form-input {
  width: 100% !important;
  height: 37px !important;
  border: 0.5px solid rgba(185, 49, 96, 0.11) !important;
  background-color: rgba(185, 49, 96, 0.11) !important;
  border-radius: 10px;
  padding: 16px;
  font-size: 14px;
  cursor: pointer;
}

.form-input:focus {
  border: 1px solid var(--themePrime--) !important;
  outline: none;
  background-color: #fefdff !important;
}

.prime-input label {
  margin-bottom: 5px !important;
  font-size: 16px;
  font-weight: 500;
  color: var(--black--);
}

.select_product {
  margin-bottom: 5px !important;
  font-size: 16px;
  font-weight: 500;
  color: var(--black--);
}
label {
  margin-bottom: -5px !important;
}
/* For Input Type Password */

.passHideShow {
  position: absolute;
  top: 35px;
  right: 12px;
  cursor: pointer;
}

/* For Input Type Search */

.searching {
  position: absolute;
  top: 6px;
  right: 12px;
  cursor: pointer;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  font-size: 16px;
}

/* For Input Type Number */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Type Radio And Check Box */

.prime-input.radio,
.prime-input.checkbox {
  display: flex;
  align-items: center;
}

.prime-input.radio label,
.prime-input.checkbox label {
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
}

.form-input[type="radio"],
.form-input[type="checkbox"] {
  height: 18px !important;
  width: 18px !important;
}

.form-input[type="radio"],
.form-input[type="checkbox"] {
  accent-color: var(--themePrime--);
}

/* For Input Type File */

.form-input[type="file"]::file-selector-button {
  margin-right: 10px;
  border: none;
  background: var(--themePrime--);
  padding: 0px 20px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  height: 35px;
}

.form-input[type="file"] {
  padding: 0px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.errorMessage {
  color: #ad0f0f;
    font-size: 14px;
    font-weight: 500;
}

/* ======================== End Input CSS ======================== */

/* ======================== Start Button CSS ======================== */

.themeBtn {
  border: 0;
  padding: 10px;
  cursor: pointer !important;
}

/* Button Background Color CSS */

.btnPrime {
  background-color: var(--btnPrime--) !important;
  color: var(--white--) !important;
}

.btnthemePrime {
  background-color: var(--themePrime--) !important;
  color: var(--white--) !important;
}
.btnBlackPrime {
  background-color: var(--prime--);
}
.btnLightPrime {
  background-color: var(--blackPrime--);
}
/* ======================== End Button CSS ======================== */

/* ======================== Start Toggle Switch CSS ======================== */
.checkbox {
  display: none;
}

/* The switch - the box around the slider */
.switch {
  font-size: 13px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.5s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.3em;
  bottom: 0.3em;
  background-color: white;
  transition: 0.3s cubic-bezier(0, 2.18, 0.64, 0.69);
}

input:checked + .slider {
  background-color: var(--prime--);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--prime--);
}

input:checked + .slider:before {
  transform: translateX(1.5em);
}

/* ======================== End Toggle Switch CSS ======================== */

/* ======================== Start Loader CSS ======================== */
.mainLoaderBox {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000070;
  width: 100%;
  height: 100%;
  z-index: 111111;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent var(--themePrime--) var(--themePrime--);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.loader::before {
  width: 32px;
  height: 32px;
  border-color: var(--prime--) var(--prime--) transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

/* ======================== End Loader CSS ======================== */

/* ======================== Start Table CSS ======================== */

.primeHeader {
  /* border-bottom: 1px solid var(--grayPrime--); */
  padding: 10px 30px;
  /* background-color: var(--headerPrime--); */
}

.titlePath {
  font-weight: 500;
}

.primeMain {
  min-height: 69vh;
  max-height: 69vh;
  overflow: auto;
  /* margin-top: 10px; */
  font-size: 14px;
  border-radius: 20px;

  /* padding: 0 20px; */
  font-family: var(--fontJakarta--);
}

.tableHeader {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableMain {
  background-color: white;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  /* overflow: hidden !important;  */
  margin: 0px 18px;
}

/* .primeTable {} */

/* .primeTable tbody tr:nth-child(even) {
  background: #f2f2f2;
} */
/* .primeTable tr:hover {
  background-color: #f1f5f9;
} */
.primeTable tr th {
  /* background-color: #f0f0f0; */
  border-bottom: 0.5px solid #f0f0f0;
  padding: 12.8px;
  font-family: var(--fontJakarta--);
  font-size: 18px;
}
.primeTable thead {
  border-bottom: 1px solid #f0f0f0;
  background-color: #f3f3f9 !important;
  color: #1a1f3a;
}
.primeTable tr td {
  padding: 12.8px;
  border-bottom: 1px solid #f0f0f0;
  font-family: var(--fontJakarta--);
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 500;
}

.tableBoldFont {
  color: var(--TableColumnBold--);
  /* font-size: 14px;  */
  font-weight: 500;
}

/* pagination CSS */
.paginationFooter {
  position: sticky;
  bottom: 0;
}

.pagination {
  width: 100%;
  /* background-color: var(--white--); */
  padding: 14px 20px;
  border-radius: 20px;
}

.paginationBtn:disabled {
  opacity: 0.8;
}

/* ================================================Pagination CSS=========================================== */
.pagination {
  /* border-top: 1px solid var(--scrollColor--); */
  display: flex;
  justify-content: end;
  align-items: center;
  overflow: auto;
}
.pageOption {
  padding: 10px 15px;
  border-radius: 15%;
}
.count {
  margin: 15px;
}
.page-btn {
  height: 40px;
  width: 40px;
  margin: 5px;
  /* padding: 8px 17px; */
  border-radius: 50%;
  background-color: #e2e8f0;
  color: #000;
  border: none;
  opacity: 0.5;
}
.active {
  height: 40px;
  width: 40px;
  margin: 5px;
  /* padding: 10px 17px; */
  border-radius: 50%;
  background-color: #b93160;
  color: #ffff;
  border: none;
}
.active-btn {
  height: 40px;
  width: 40px;
  margin: 5px;
  /* padding: 10px 15px; */
  border-radius: 50%;
  /* background-color: #b93160; */
  color: #000;
  border: none;
}
.pagination .makeStyles-root-29 {
  display: flex !important;
}
/* Pagination Selection */

.tableSelection {
  margin-right: 15px;
  padding: 5px;
}

/* Dialogue CSS */

.mainDialogue {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 111;
  top: 0;
  left: 0;
  background-color: #00000050;
  opacity: 0;
  animation: fade-in 0.1s ease-in;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.1s ease-in;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Dialogue {
  width: 600px;
  min-height: 290px;
  background-color: var(--white--);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 111;
  border-radius: 10px;
}

@media screen and (max-width: 630px) {
  .Dialogue {
    width: 97%;
  }
}

/* Header */
.dialogueHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  padding: 20px;
}

.headerTitle {
  font-size: 22px;
}

.closeBtn {
  background-color: var(--themePrime--);
  color: var(--white--);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
}

/* Main */
.dialogueMain {
  padding: 20px;
  text-align: left;
}

/* Footer */
.dialogueFooter {
  padding: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.daterange {
  text-align: center !important;
}

/* ======================== End Table CSS ======================== */

/* ======================== Start Scroll CSS ======================== */

/* width */
::-webkit-scrollbar {
  height: 2px;
  width: 2.5px;
  background-color: transparent;
  overflow: hidden !important;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollColor--);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  transition: all 5s ease-in-out;
  background: var(--scrollColor--);
}

/* ======================== End Scroll CSS ======================== */

/* ======================== Start Setting Box CSS ======================== */

.settingMain {
  padding: 20px;
}

.settingBox {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 5px #0000005c;
}

.settingInnerBox .prime-input {
  margin-bottom: 5px !important;
}

.settingBoxHeader {
  margin-bottom: 15px;
}

.mainTitle {
  font-size: 22px;
  font-weight: bold;
}

/* ======================== End Setting Box CSS ======================== */

/* ======================== Start Dashboard Box CSS ======================== */
.mainDashboard {
  padding: 30px;
}
.dashboardBoxImage {
  position: relative;
}
.dashboardBoxImage::before {
  content: " ";
  background-image: url("../images/Mask group.png") !important;
  /* z-index: 111; */
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover !important;
  box-sizing: border-box !important;
  width: 100% !important;
  height: 100%;
  opacity: 1 !important;
}

.dashboardBox {
  padding: 15px !important;
  /* background-color: #ededed; */
  border-radius: 10px;
  height: 182px;
  border: 1px solid #e6e6e6;
  background: #fff;
  /* box-shadow: 0px 0px 8px #eaeaea; */
}
.dashCard img {
  object-fit: cover !important;
  box-sizing: border-box !important;
}
.dashOption {
  color: var(--black--);
  font-weight: 500;
  font-size: 24px;
}
.timeBox {
  border: 2px solid var(--btnPrime--);
}

.timeBox li a {
  color: #636e76;
  font-size: 15px;
  font-weight: 500;
  padding: 0px 19px;
  line-height: 40px;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.profile {
  color: var(--bs-color);
  font-weight: 500;
  font-size: 24px;
  border-bottom: 1px solid var(--grayPrime--);
}
.activeBox {
  background-color: var(--btnPrime--);
  color: #fff !important;
}
.dashIcon {
  font-size: 32px;
  /* padding: 15px; */
  border-radius: 12px;
  /* flex-shrink: 0; */
  width: 80px;
  height: 80px;
}
.dashIcon i {
  padding: 15px !important;
  width: 64px;
  height: 64px;
}
.dashIcon img {
  padding: 12px !important;
  width: 64px;
  height: 64px;
}

.dName {
  font-size: 17px;
}

.dCount {
  font-size: 40px;
  line-height: 54px;
}

.showMoreBtn {
  margin-top: 10px;
}
.heading {
  border-bottom: 1px solid var(--grayPrime--);
  /* height: 70px; */
  border-radius: 22px 22px 0px 0px;
  color: var(--white--);
  background: #f4f4f5;
  /* box-shadow: 0px 3px 10px -3px rgba(0, 0, 0, 0.16); */
  color: #141a03;
}
.heading2 {
  border-bottom: 1px solid var(--grayPrime--);
  height: 60px;
  background-color: #fff;
  border-radius: 22px 22px 0px 0px;
  color: #141a03;
}

/* ======================== End Dashboard Box CSS ======================== */

/* ======================== Start DatePicker CSS ======================== */

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: var(--fontJakarta--);
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before,
.daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--blackPrime--);
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: var(--blackPrime--);
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges,
  .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges,
  .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

/* ======================== End DatePicker CSS ======================== */
/* ======================== Start Badge CSS ======================== */
.badge {
  display: inline-block;
  padding: 0.5em 0.6em;
  font-size: 14px;
  width: 115px;
  font-weight: 600;
  line-height: 1;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:focus,
a.badge:hover {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #007BFF;
  background-color: #bfddfc;
}

a.badge-primary:focus,
a.badge-primary:hover {
  color: #007BFF;
  background-color: #bfddfc;
}

a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #28a745;
  background-color: #daf4f0;
}

a.badge-success:focus,
a.badge-success:hover {
  color: #28a745;
  background-color: #daf4f0;
}

a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #343a40;
  background-color: #e1ebfd;
}

a.badge-info:focus,
a.badge-info:hover {
  color: #343a40;
  background-color: #e1ebfd;
}

a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #a17a04;
  background-color: #fef4e4;
}

a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #DC3545;
  background-color: #fde8e4;
}

a.badge-danger:focus,
a.badge-danger:hover {
  color: #DC3545;
  background-color: #fde8e4;
}

a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badgeEra {
  display: inline-block;
  padding: 0.5em 0.6em;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-era {
  color: #fff;
  background-color: #b93160;
  padding: 5px 18px;
  font-size: 13px;
  width: 30px !important;
  font-style: normal;
  border-radius: 8px;
  font-weight: 600;
  line-height: normal;
}

a.badge-era:focus,
a.badge-era:hover {
  color: #fff;
  background-color: #0062cc;
}

a.badge-era.focus,
a.badge-era:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* slider arrow  */

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 10px);
  right: auto;
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);

  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px !important;
  width: 32px !important;
  background-color: rgba(64, 81, 137, 0.2) !important;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px) !important;
  border-radius: 8px !important;
}

swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "prev";
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 20px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  letter-spacing: 0;
  /* font-feature-settings: ; */
  font-variant: initial;
  line-height: 1;
  color: #b93160 !important;
}

.text-era{
  color: var(--blackPrime--);
}

select {
  -webkit-appearance: none;
  appearance: none;
}

select {
  background-image: url("../images/free-arrow-down-icon-3101-thumb.png");
  background-size: 12px;
  background-repeat: no-repeat;
  padding-right: 30px !important;
  padding-left: 20px !important;
  background-position: calc(100% - 8px) center;
}
img{
  object-fit: cover !important;
}
.rdrDateRangePickerWrapper{
  border: 1px solid #cfcfcf;
}